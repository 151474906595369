/** @jsx jsx */
// import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import { buildImageObj } from "../../../lib/helpers";
import { imageUrlFor } from "../../../lib/image-url";
// import React from 'react'
import SanityTextBlockEmbed from "../SanityTextBlockEmbed";
// import classNames from "classnames"
// import styles from 'image-text.module.css'
import { root, modern, right, left } from "./image-text.module.css";
import cx from "classnames";
import { jsx } from "theme-ui";


// import Img from 'gatsby-image'

const ImgTxt = ({value, image, locale, position, variant}) => {
  var myClass = () => {
    if (variant == "modern" && position == "left") return cx(root, modern, left)
    if (variant == "modern" && position == "right") return cx(root, modern, right)

    // if(variant== 'simple' && position =='left') return  cx(root, modern,left);
    // if(variant== 'simple' && position =='right') return  cx(root, modern,right);
  }

  return (
    <div
      // className="img-txt flex flex-col md:flex-row"
      // className={cx("img-txt", [position, variant])}
      // className={cx([root, position, {modern: variant==modern}])}
      className={myClass()}

      // sx={{ variant: 'containers.imageText' }}
    >
      {image && image.asset && (
        <img
          className="imageWrapper"
          // sx={{ variant: 'containers.imageText.img' }}
          // className="w-full p-6 flex"
          src={imageUrlFor(buildImageObj(image))
            .width(682)
            .height(909)
            // .height(Math.floor((9 / 16) * 600))
            .url()}
          alt={image.alt}
        />
      )}
      <article
        className="articleWrapper"

        // className="w-full md:w-2/3 xl:w-2/4 p-6 "
        // className={cx([position, variant])}

        // sx={{ variant: 'containers.imageText.text' }}
      >
        <SanityTextBlockEmbed value={value || []} />
      </article>
    </div>
  )
}

export default ImgTxt