// import BaseBlockContent from "@sanity/block-content-to-react"
import {PortableText} from "@portabletext/react"

import React from "react"
// import slugify from "slugify"

import {
  // root,
  blockQuote,
  responsiveTitle1,
  responsiveTitle2,
  responsiveTitle3,
  responsiveTitle4,
  paragraph
} from "../../styles/typography.module.css"

const serializers = {

  marks: {
    em: ({children}) => <em className="text-gray-600 font-semibold">{children}</em>,

    internalLink: ({children, value}) => {
      console.log("mark", value)
      console.log("children", children)
      return (
        <a>hardcoded link</a>
        //   <a href={value.slug.current}>
        //   {children}
        // </a>
      )
    },
    link: ({value, children}) =>
      value?.blank ? (
        <a href={value?.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={value?.href}>{children}</a>
      )
  }


}

const components = {
  block: {
    // Customize block types with ease
    h1: ({ children }) => <h1 className={responsiveTitle1}>{children}</h1>,
    h2: ({ children }) => <h2 className={responsiveTitle2}>{children}</h2>,
    h3: ({ children }) => <h3 className={responsiveTitle3}>{children}</h3>,
    h4: ({ children }) => <h4 className={responsiveTitle4}>{children}</h4>,
    blockquote: ({ children }) => <blockquote className={blockQuote}>{children}</blockquote>,
    normal: ({ children }) => <p className={paragraph}>{children}</p>,
  },
  marks: {
    em: ({ children }) => <em className="text-gray-600 font-semibold">{children}</em>,

    internalLink: ({ children, value }) => {
      console.log("mark", value)
      console.log("children", children)
      return (
        <a>hardcoded link</a>
        //   <a href={value.slug.current}>
        //   {children}
        // </a>
      )
    },
    link: ({ value, children }) =>
      value?.blank ? (
        <a href={value?.href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ) : (
        <a href={value?.href}>{children}</a>
      )
  }
  // marks: {
  //   em: ({ children }) => <em className="text-gray-600 font-semibold">{children}</em>,

  //   link: ({ children, value }) => {
  //     const rel = !value.href.startsWith('/') ? 'noreferrer noopener' : undefined
  //     return (
  //       <a href={value.href} rel={rel}>
  //         {children}
  //       </a>
  //     )
  //   },
  // },
};



const SanityTextBlockEmbed = ({ value,item}) => {
  // console.log('value', value);
  // console.log('item',item);
  return (
    <PortableText value={value} components={components} />
  )
}

export default SanityTextBlockEmbed
