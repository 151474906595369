/** @jsx jsx */
import localize from "../localize";
import { root, form, formItem } from "./contact.module.css";
import { Fragment } from "react";
import { jsx } from "theme-ui";


const ContactBlock = ({locale, sentMessage, title}) => {
  return (
    <div className={root}>
      {locale === "en" ? (
        <Fragment>
          <h2>{title}</h2>
          <form
            className={form}
            name="contact"
            method="post"
            action="/thanks"
            // action="/en/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <noscript>This form won’t work with Javascript disabled</noscript>
            <input type="hidden" name="form-name" value="contact" />
            {/* <input
              type="hidden"
              name="subject"
              value="New contact form message from agatajuliamakeup.com"
            /> */}

            <div className={formItem}>
              <label>
                Your name:
                <br />
                <input type="text" name="name" />
              </label>
            </div>
            <div className={formItem}>
              <label>
                Your email:
                <br />
                <input type="email" name="email" />
              </label>
            </div>
            <div className={formItem}>
              <label>
                Message:
                <br />
                <textarea name="message" />
              </label>
            </div>
            <div className={formItem}>
              <button type="submit">Send</button>
            </div>
          </form>
        </Fragment>
      ) : (
        <Fragment>
          <h2>{title}</h2>
          <form
            className={form}
            name="contact"
            method="post"
            action="/es/gracias"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <noscript>This form won’t work with Javascript disabled</noscript>

            <input type="hidden" name="form-name" value="contact" />
            <input
              type="hidden"
              name="subject"
              value="{{name}} has sent a message from agatajuliamakeup.com"
            />
            <div className={formItem}>
              <label>
                Tu nombre:
                <br />
                <input type="text" name="name" />
              </label>
            </div>
            <div className={formItem}>
              <label>
                Tu email:
                <br />
                <input type="email" name="email" />
              </label>
            </div>
            <div className={formItem}>
              <label>
                Mensaje:
                <br />
                <textarea name="message" />
              </label>
            </div>
            <div className={formItem}>
              <button type="submit">Enviar</button>
            </div>
          </form>
        </Fragment>
      )}
    </div>
  )
}

// export default localize(ContactBlock, { propsTotranslate: ['title'] })
export default ContactBlock