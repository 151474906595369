/** @jsx jsx */
import {buildImageObj} from "../../../lib/helpers"
import {imageUrlFor} from "../../../lib/image-url"
import {root, article, imageWrapper} from "./hero.module.css"
import {Link} from "gatsby"
import Img from "gatsby-image"
import SanityImage from "gatsby-plugin-sanity-image"
import {Fragment} from "react"
import {jsx} from "theme-ui"


const Hero = ({ image, title, subtitle, cta, locale, topPosition }) => {
  // console.log('topPosition', topPosition)
  return (
    <div id="hero" className={root}>
       <div className={imageWrapper}>
        {/* <img src="../hero.jpg" /> */}
       <SanityImage
              // pass asset, hotspot, and crop fields
              {...image}
              // tell Sanity how large to make the image (does not set any CSS)
              width={1024}
              height={787}
              // style it how you want it
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              loading={topPosition ? "eager": "lazy"}
              alt={image.alt}
              title={title}
              // srcSet={image.asset.fixed.srcSet}
              // srcSetWebp={image.asset.fixed.srcSet}
              // srcWebp={image.asset.fixed.srcWebp}
            />
       </div>
       <div className={article}>
            {title && <h2>{title}</h2>}
            {subtitle && <h3>{subtitle}</h3>}
          </div>
      {/* {image && image.asset ? (
        <Fragment>
          <div className={imageWrapper}>
            <SanityImage
              // pass asset, hotspot, and crop fields
              {...image}
              // tell Sanity how large to make the image (does not set any CSS)
              width={1024}
              height={787}
              // style it how you want it
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
              loading={topPosition ? "eager": "lazy"}
              alt={image.alt}
              title={title}
              // srcSet={image.asset.fixed.srcSet}
              // srcSetWebp={image.asset.fixed.srcSet}
              // srcWebp={image.asset.fixed.srcWebp}
            />
          </div>
          <div className={article}>
            {title && <h2>{title}</h2>}
            {subtitle && <h3>{subtitle}</h3>}
            {cta && cta.route && cta.title && (
              <Link className="bg-accent-one text-white" to={cta.route}>
                {cta.title}
              </Link>
            )}
          </div>
        </Fragment>
      ) : (
          <Fragment>
          <div className={article}>
            {title && <h2>{title}</h2>}
            {subtitle && <h3>{subtitle}</h3>}
            {cta && cta.route && (
              <Link className="text-white bg-brand-accent-one" to={cta.route}>
                {cta.title}
              </Link>
            )}
          </div>
          </Fragment>
      )} */}
    </div>
  )
}
export default Hero
