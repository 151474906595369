/** @jsx jsx */
// import { getFluidGatsbyImage } from 'gatsby-source-sanity'
import {buildImageObj} from "../../../lib/helpers"
import {imageUrlFor} from "../../../lib/image-url"
import SanityTextBlockEmbed from "../SanityTextBlockEmbed"
import {root, imageWrapper, article} from "./image-text.module.css"
// import React from 'react'
import {Link} from "gatsby"
import Img from "gatsby-image"
import {jsx} from "theme-ui"

const ImgTxtCTA = ({blocks, image, locale, title, cta, position}) => {

  return (
    <div className={root}>
      {image && image.asset && (
        <div className={imageWrapper}>
          <img
            // sx={{ variant: "containers.imageText.img" }}
            src={imageUrlFor(buildImageObj(image))
              .width(400)
              .height(400)
              // .height(Math.floor((9 / 16) * 600))
              .url()}
            alt={image.alt}
          />
        </div>
      )}

      <div className={article}>
        <h3>{title}</h3>
        {cta && cta.route && cta.title && <Link to={cta.route}>{cta.title}</Link>}
      </div>
    </div>
  )
}

export default ImgTxtCTA
