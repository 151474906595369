import BlockContent from "../components/block-content";
import ContactBlock from "../components/body/ContactBlock";
import SanityTextBlockEmbed from "../components/body/SanityTextBlockEmbed";
import Hero from "../components/body/hero/Hero";
import ImgTxt from "../components/body/image-text/image-text";
import ImgTxtCTA from "../components/body/image-text/image-text-cta";
import Testimonial from "../components/body/testimonial/testimonial";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import LayoutContainer from "../components/layout-container";
import PreviewGrid from "../components/lists-preview/preview-grid";
import localize from "../components/localize";
import SEO from "../components/seo";
import SeoContext from "../containers/SeoContext";
import Layout from "../containers/layout";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import { responsiveTitle1 } from "../styles/typography.module.css";
import classNames from "classnames";
import { graphql } from "gatsby";
import React from "react";


// import { templateType } from "./page.module.css"
// import typography from "../styles/typography.module.css"

// import InstagramBlock from "../components/instagram-block"
export const query = graphql`
  #   fragment SanityImage on SanityMainImage {
  #     hotspot {
  #       height
  #       width
  #       x
  #       y
  #     }
  #     crop {
  #       bottom
  #       left
  #       right
  #       top
  #     }
  # asset {
  #   # _id

  #                   altText
  #                 }
  #   }

  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: {eq: $id}) {
      seo: openGraph {
        title
        description
        image {
          asset {
            _id
          }
        }
      }
      id
      _id
      _type
      # _rawContent(resolveReferences: {maxDepth: 10})

      # hero {
      #   _type
      #   subtitle {
      #     _key
      #     _type
      #     en
      #     es
      #   }
      #   illustration {
      #     image {
      #       ...SanityImage
      #       alt
      #     }
      #   }
      #   ctaHero {
      #     title {
      #       en
      #       es
      #       _type
      #     }
      #     link {
      #       en
      #       es
      #       _type
      #     }
      #     kind
      #     route {
      #       es
      #       en
      #       _type
      #     }
      #   }
      #   title {
      #     es
      #     en
      #     _type
      #   }
      # }

      content {
        # ... on SanityInstagramPosts {
        #   _key
        #   _type
        #   title {
        #     es
        #     en
        #     _type
        #   }
        # }
        ... on SanityTextBlockEmbed {
          _type
          _id
          _key
          _rev
          _rawBody(resolveReferences: {maxDepth: 10})
          # bg {
          #   bgColor
          #   bgToggle
          # }
          body {
            _key
            _type
            en {
              _key
              _type
              list
              style
              sanityChildren {
                marks
                _key
                _type
                text
              }
            }
            es {
              _key
              _type
              list
              style
              sanityChildren {
                marks
                _key
                _type
                text
              }
            }
          }

          title {
            es
            en
            _type
          }
        }
        ... on SanityTestimonial {
          _type
          url
          quote {
            _type
            en
            es
          }
          bg {
            bgColor
            bgToggle
          }
          name
          image {
            # id
            ...ImageWithPreview
            alt
            asset {
              # altText
              _id
            }
            # asset {

            #   _id
            #   fluid(maxWidth: 700) {
            #     ...GatsbySanityImageFluid
            #     # aspectRatio
            #     # sizes
            #     # srcSet
            #     # srcSetWebp
            #     # srcWebp
            #   }
            #   metadata {
            #     dimensions {
            #       height
            #       width
            #     }
            #      lqip
            #   }
            #   # fluid {
            #   #   aspectRatio
            #   # }
            #   fixed {
            #     aspectRatio
            #     srcSet
            #     srcSetWebp
            #     srcWebp
            #   }
            # }
            # alt
          }
        }
        ... on SanityReferenceGrid {
          _type
          cols
          title {
            _type
            en
            es
          }
          bg {
            bgColor
            bgToggle
          }

          previewItem {
            ... on SanityService {
              id
              _type
              slug {
                en {
                  current
                }
                _type
                es {
                  current
                }
              }
              mainImage {
                ...ImageWithPreview
                alt
                asset {
                  altText
                  _id
                }
                # asset {
                #   _id
                #   fluid(maxWidth: 300) {
                #     ...GatsbySanityImageFluid
                #   }
                #   fixed(width: 350) {
                #     ...GatsbySanityImageFixed
                #   }
                # }
                # crop {
                #   _key
                #   _type
                #   top
                #   bottom
                #   left
                #   right
                # }
                # hotspot {
                #   _key
                #   _type
                #   x
                #   y
                #   height
                #   width
                # }
              }
              title {
                es
                en
                _type
              }
            }
            ... on SanityPortfolio {
              id
              _type

              title {
                es
                en
                _type
              }
              slug {
                es {
                  current
                }
                en {
                  current
                }
                _type
              }
              mainImage {
                ...ImageWithPreview
                # asset {
                #   altText
                #   _id
                # }

                asset {
                  _id
                  altText

                  fluid(maxWidth: 1200) {
                    ...GatsbySanityImageFluid
                    srcSet
                    srcSetWebp
                    srcWebp
                  }
                  fixed(height: 350) {
                    ...GatsbySanityImageFixed
                    srcSet
                    srcSetWebp
                    srcWebp
                  }
                }
                crop {
                  _key
                  _type
                  top
                  bottom
                  left
                  right
                }
                hotspot {
                  _key
                  _type
                  x
                  y
                  height
                  width
                }
              }

              gallery {
                crop {
                  _key
                  _type
                  bottom
                  left
                  right
                  top
                }
                _key
                _type
                alt
                caption
                asset {
                  _id
                  assetId
                  altText
                  fixed {
                    aspectRatio
                    base64
                    src
                    height
                    srcSet
                    srcSetWebp
                    srcWebp
                    width
                  }
                  fluid {
                    srcWebp
                    srcSetWebp
                    srcSet
                    src
                    sizes
                    base64
                    aspectRatio
                  }
                  metadata {
                    _key
                    _type
                    dimensions {
                      aspectRatio
                      height
                      width
                    }
                  }
                  source {
                    sanityId
                    url
                  }
                }
              }
            }
          }
        }

        ... on SanityImageTextBlock {
          _type
          position
          variant

          title {
            es
            en
            _type
          }
          # bg {
          #   bgColor
          #   bgToggle
          # }
          mainImage {
            ...ImageWithPreview
            asset {
              altText
              _id
            }
            alt
            # asset {
            #   _id
            #   fluid(maxWidth: 300) {
            #     ...GatsbySanityImageFluid
            #   }
            #   fixed(width: 350) {
            #     ...GatsbySanityImageFixed
            #   }
            # }
            # crop {
            #   _key
            #   _type
            #   top
            #   bottom
            #   left
            #   right
            # }
            # hotspot {
            #   _key
            #   _type
            #   x
            #   y
            #   height
            #   width
            # }
          }
          _rawBody(resolveReferences: {maxDepth: 10})
        }
        ... on SanityImageTextCTA {
          _type
          position
          title {
            es
            en
            _type
          }
          cta {
            title {
              en
              es
              _type
            }
            link {
              en
              es
              _type
            }
            kind
            route {
              es
              en
              _type
            }
          }
          mainImage {
            ...ImageWithPreview
            alt
            asset {
              altText
              _id
            }
            # asset {
            #   _id
            #   fluid(maxWidth: 300) {
            #     ...GatsbySanityImageFluid
            #     # srcSet
            #     #   srcSetWebp
            #     #   srcWebp
            #   }
            #   fixed(width: 350) {
            #     ...GatsbySanityImageFixed
            #     # srcSet
            #     #   srcSetWebp
            #     #   srcWebp
            #   }
            # }
            # crop {
            #   _key
            #   _type
            #   top
            #   bottom
            #   left
            #   right
            # }
            # hotspot {
            #   _key
            #   _type
            #   x
            #   y
            #   height
            #   width
            # }
          }
          # _rawBody
        }
        ... on SanityHero {
          _type
          subtitle {
            _key
            _type
            en
            es
          }
          illustration {
            image {
              asset {
                _id
                # altText
                fluid {
                  ...GatsbySanityImageFluid
                  # srcSet
                  # srcSetWebp
                  # srcWebp
                }
              }
              alt
            }
          }
          ctaHero {
            title {
              en
              es
              _type
            }
            link {
              en
              es
              _type
            }
            kind
            route {
              es
              en
              _type
            }
          }
          title {
            es
            en
            _type
          }
        }
        # ... on SanityAccordion {
        #   _type
        #   title {
        #     en
        #     es
        #     _type
        #   }
        #   content {
        #     en
        #     es
        #     _type
        #   }
        # }
        ... on SanityContact {
          _type
          title {
            en
            es
            _type
          }
          sentMessage {
            en
            es
            _type
          }
        }
        ... on SanityPreviewGrid {
          cols
          _type
          previewType
          title {
            en
            es
            _type
          }
          bg {
            bgColor
            bgToggle
          }
          previewItem {
            title {
              en
              es
              _type
            }
            subtitle {
              en
              es
              _type
            }
            slug {
              es {
                current
              }
              en {
                current
              }
              _type
            }
            mainImage {
              ...ImageWithPreview
              asset {
                _id
                altText
                _id
              }
              # asset {
              #   _id
              #   fluid(maxWidth: 700) {
              #     ...GatsbySanityImageFluid
              #     # srcSet
              #     # srcSetWebp
              #     # srcWebp
              #   }
              #   fixed(width: 350) {
              #     ...GatsbySanityImageFixed
              #     # srcSet
              #     # srcSetWebp
              #     # srcWebp
              #   }
              # }
              # crop {
              #   _key
              #   _type
              #   top
              #   bottom
              #   left
              #   right
              # }
              # hotspot {
              #   _key
              #   _type
              #   x
              #   y
              #   height
              #   width
              # }
            }
          }
        }
      }
      title {
        es
        en
        _type
      }
      slug {
        es {
          current
        }
        en {
          current
        }
        _type
      }
      seo: openGraph {
        title
        description
        image {
          asset {
            _id
          }
        }
      }
    }
  }
`

const PageTemplate = ({data, errors, pageContext}) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  const page = data && data.page

  // console.log('id',page.id)
  const locale = pageContext.locale
  const altLangUrl = pageContext.altLang
  const templateType = pageContext.template

  const seo = {
    title: data?.page?.seo?.title,
    description: data?.page?.seo?.description,
    image: data?.page?.seo?.image,
    altLangUrl: pageContext.altLangUrl,
    locale: pageContext.locale
  }

  var hiddenClass = ""
  if (
    page.title == "Bienvenidos a Agata Julia peluqueria y maquillaje" ||
    page.title == "Welcome to Agata Julia Hair & Makeup"
  ) {
    hiddenClass = "hidden"
  }
  const hero = (page || {}).hero

  const content = (page.content || []).map((item, key) => {
    let el = null
    // console.log("item", item)

    switch (item._type) {
      // case "instagramPosts":
      //   el = <InstagramBlock key={key} {...item} titles={item.title} />
      //   break
      case "textBlockEmbed":
        // console.log("textBlockEmbed", item)
        el = (
          <div class="textBlockEmbed">
            <div class="wrapper">
              <SanityTextBlockEmbed key={key} item={item} value={item._rawBody || []} />
            </div>
          </div>
        )
        break
      case "imageTextBlock":
        // console.log("imageTextBlock", item)
        el = (
          <ImgTxt
            key={key}
            image={item.mainImage}
            value={item._rawBody || []}
            position={item.position}
            variant={item.variant}
            locale={item.locale}
            {...item}
          />
        )
        break
      case "imageTextCTA":
        // console.log("imageTextCTA", item)
        el = (
          <ImgTxtCTA
            key={key}
            image={item.mainImage}
            cta={item.cta}
            blocks={item._rawBody || []}
            position={item.position}
            locale={item.locale}
            {...item}
          />
        )
        break
      case "previewGrid":
        // console.log("previewGrid", item)
        //   console.log(JSON.stringify(item))

        el = (
          <PreviewGrid
            key={key}
            cols={item.cols}
            title={item.title}
            nodes={item.previewItem}
            type={item.previewType}
            locale={locale}
            bg={item.bg}
            {...item}
          />
        )
        break
      case "referenceGrid":
        // console.log('referenceGrid', item)
        el = (
          <PreviewGrid
            key={key}
            cols={item.cols}
            title={item.title}
            nodes={item.previewItem}
            locale={locale}
            bg={item.bg}
            {...item}
          />
        )
        break
      case "hero":
        // console.log("hero page", item)
        // console.log("hero key", key)
        el = (
          <Hero
            key={key}
            title={item.title}
            subtitle={item.subtitle}
            cta={item.ctaHero}
            image={item.illustration?.image}
            locale={locale}
            topPosition={key === 0 ? true : false}
            {...item}
          />
        )
        break
      case "testimonial":
        // console.log("testimonial", item)
        el = (
          <Testimonial
            key={key}
            name={item.name}
            quote={item.quote}
            url={item.url}
            image={item.image}
            locale={locale}
            direction={"direction-" + (key % 2 ? "r" : "l")}
            bg={item.bg}
            {...item}
          />
        )
        break
      case "contact":
        el = (
          <ContactBlock
            key={key}
            title={item.title}
            sentMessage={item.sentMessage}
            locale={locale}
            {...item}
          />
        )
        break
      default:
        el = null
    }
    return el
  })

  if (!page) {
    throw new Error(
      'Missing page data. Open the studio at http://localhost:3333 and add "About" page data and restart the development server.'
    )
  }
  // console.log("pageContext", pageContext)
  return (
    <SeoContext.Provider value={page.seo}>
      <LayoutContainer locale={locale} hero={hero} altLangUrl={altLangUrl}>
        {seo && (
          <SEO
            title={page?.title || seo?.title}
            description={seo?.description}
            image={seo?.image}
            lang={locale}
            altLangUrl={altLangUrl}
          />
        )}
        <Container templateType={templateType}>
          {pageContext.homepage && (
            <h1
              hidden
              // className="text-center font-heading text-xl md:text-4xl"
              // className={classNames([hiddenClass, responsiveTitle1])}
            >
              {page.title}
            </h1>
          )}
          {!pageContext.homepage && (
            <h1 className={classNames([hiddenClass, responsiveTitle1])}>{page.title}</h1>
          )}

          {/* <BlockContent blocks={page._rawBody || []} /> */}
          {hero && (
            <Hero
              title={hero.title}
              subtitle={hero.subtitle}
              cta={hero.ctaHero}
              image={
                page.hero?.illustration?.image?.asset.fluid
                // page.hero.illustration &&
                // page.hero.illustration.image
                //   .asset.fluid
              }
              locale={locale}
            />
          )}
          {content}
        </Container>
      </LayoutContainer>
    </SeoContext.Provider>
  )
}

export default localize(PageTemplate)