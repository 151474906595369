/** @jsx jsx */
import {buildImageObj} from "../../../lib/helpers"
// import React from 'react'
import {imageUrlFor} from "../../../lib/image-url"
import {
  root,
  article,
  imageWrapper,
  itemReviewed,
  reviewTextWrapper,
  figure,
  figcaption,
  quote,
  blockquote,
  clientName,
  city
} from "./testimonial.module.css"
// import localize from '../localize'
import Img from "gatsby-image"
import SanityImage from "gatsby-plugin-sanity-image"
import {jsx} from "theme-ui"

const Testimonial = ({name, quote, url, image, locale, direction, bg}) => {
  const bgColorInline = bg?.bgToggle == "on" ? bg?.bgColor : "transparent"

  // console.log("testimonialimage",image)

  return (
    <div
      className={`${direction} ${root}`}
      vocab="https://schema.org/"
      typeof="Review"
      // sx={{backgroundColor: bgColorInline}}
    >
      <div
        className={itemReviewed}
        property="itemReviewed"
        typeof="LocalBusiness"
        // sx={{ variant: 'containers.testimonial.container' }}
      >
        {/* {image && (
          <img
            // className={styles.img}
            src={imageUrlFor(buildImageObj(image))
              .width(200)
              .height(Math.floor((9 / 16) * 200))
              .url()}
            alt={image.alt}
          />
        )} */}
        {/* <div property="reviewRating" typeof="Rating">
          <q property="reviewBody">{quote}</q>
          <h2 property="author" typeof="Person">
            <span property="name"> {name}</span>
          </h2>
        </div> */}

        <figure className={figure}>
          {image && (
            // <Image width={492} height={492} className={imageWrapper}
            // className="w-32 h-32 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto"
            // className={styles.img}
            // TODO: FIX IMAGE RATIO
            // src={imageUrlFor(buildImageObj(image))
            //   .width(300)
            //   .height(300)
            //   // .height(Math.floor((9 / 16) * 200))
            //   .url()}
            // srcSet={image.asset.fixed.srcSet}
            // srcSetWebp={image.asset.fixed.srcSet}
            // srcWebp={image.asset.fixed.srcWebp}

            //   fixed={image.asset.fixed}
            //   objectFit="cover"
            //   objectPosition="50% 50%"

            //   alt={image.alt}
            // />
            <SanityImage
              // pass asset, hotspot, and crop fields
              {...image}

              alt={image.alt}
              title={name}
              // tell Sanity how large to make the image (does not set any CSS)
              // width={500}
              // style it how you want it
              className={imageWrapper}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover"
              }}
            />
          )}
          <div className={reviewTextWrapper}>
            <blockquote className={blockquote}>
              <p className={quote}>{quote}</p>
            </blockquote>
            <figcaption className={figcaption}>
              <div className={clientName}>{name}</div>
              {/* <div className={city}>London</div> */}
            </figcaption>
          </div>
        </figure>
      </div>
    </div>
  )
}

export default Testimonial

// <figure className="md:flex bg-gray-100 rounded-xl p-8 md:p-0">
//   <img className="w-32 h-32 md:w-48 md:h-auto md:rounded-none rounded-full mx-auto" src="/sarah-dayan.jpg" alt="" width="384" height="512">
//   <div className="pt-6 md:p-8 text-center md:text-left space-y-4">
//     <blockquote>
//       <p className="text-lg font-semibold">
//         “Tailwind CSS is the only framework that I've seen scale
//         on large teams. It’s easy to customize, adapts to any design,
//         and the build size is tiny.”
//       </p>
//     </blockquote>
//     <figcaption className="font-medium">
//       <div className="text-cyan-600">
//         Sarah Dayan
//       </div>
//       <div className="text-gray-500">
//         Staff Engineer, Algolia
//       </div>
//     </figcaption>
//   </div>
// </figure>
